/*eslint no-unused-vars: "off"*/

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';

import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

// s4n
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import classNames from 'classnames';
import Yup from './../../s4n/Yup';
import FormHelper from './../../s4n/Form'
import { DisplayFormikState } from "./../formikHelper"



const styles = theme => ({
  // paper: {
  //   marginTop: theme.spacing.unit * 8,
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme
  //     .spacing.unit * 5}px`
  // },
  success: {
    backgrounColor: "#4caf50",
    borderColor: "#4caf50",
  }
});

const ComponentStateless = (props) => {

  const { classes, intl } = props;
  const t = intl.formatMessage;

  const [formStatus, setFormStatus] = useState({
    success: null,
    class: null,
    message: null,
  });

  const [state, setState] = useState({
    loading: false,
    error: false,
    formSchema: null,
  });

  const formSchema = {}

  let initialValues = {
    email: '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  })

  // Set data for FormHelper START
  // set form ID!!!
  initialValues.webform_id = 'heuresis_newsletter';
  // oFormHelper.formEndpoint = '';
  const oFormHelper = new FormHelper(intl);
  
  oFormHelper.t = t;
  oFormHelper.setFormStatus = setFormStatus;
  oFormHelper.state = state;
  oFormHelper.setState = setState;
  // Set data for FormHelper STOP

  /**
   * @see: https://reactjs.org/docs/hooks-effect.html
   * Same as componentDidMount() hook because we pass an empty array [] as second argument,
   * this argument watches for changes in passed arguments
   * In componentDidMount we have access to window object elsewhere not always!!!
   */
  useEffect(() => {
    // Form schema does not contain markup elements!
    // oFormHelper.getFormSchema(initialValues.webform_id);
  }, []);


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={oFormHelper.onSubmit}
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          values,
          errors,
          status,
          touched,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        // console.log(status)

        const formStatusClass = classNames({
            // "mb-0": true,
            "alert": true,
            "d-none": null === formStatus.class,
            [`alert-${formStatus.class}`]: true,
        });

        return (
          <Form 
                onSubmit={handleSubmit} 
                // className={initialValues.webform_id}
                className="newsletter"
            >

            <div className="row">
              <div className="col">
                <TextField
                  // label={ oFormHelper.getFieldTitle(`email`) }
                  id={`${initialValues.webform_id}_email`}
                  label={ t({ id: "soft4net_webform_contact_s4n_form_email" }) }
                  name="email"
                  error={errors.email && touched.email}
                  className={classes.textField}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={(errors.email && touched.email) && errors.email}
                  margin="normal"
                />

                <DialogActions>
                  {isSubmitting ? 
                    <CircularProgress /> : 
                    <Button 
                      type="submit" 
                      variant="contained" 
                      color="primary"
                      className={formStatus && formStatus.success ? 'green' : 'orange'}
                      disabled={isSubmitting}>{ t({ id: "soft4net_form_action_submit" }) }</Button>
                  }
                </DialogActions>
              </div>
            </div>



            {/* <DisplayFormikState {...props} /> */}


            
          </Form>
        );
      }}
    </Formik>
  );
}

export default withStyles(styles)(injectIntl(ComponentStateless))