// import React from 'react';
// import React, { useState, useEffect } from 'react';
// import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'
import axios from 'axios';
// import ReactDOMServer from 'react-dom/server';



class FormHelper {
    formSchema = null;

    // Injected attributes
    // setFormStatus: null,
    // state: null,
    // setState: null,

    // formEndpoint = process.env.SOFT4NET_SOURCE_BASE_URL + '/webform_rest/submit';
    // formSchemaEndpoint: `/webform_jsonschema/${webform_id}?_format=json`,
    // setFormStatus: () => {

    constructor(intl) {
        // this.node = node;
        this.intl = intl;
    }

    
    setIntl = (intl) => {
        this.intl = intl;
    }

    getFormSchema = async (webform_id) => {
        const formSchemaEndpoint = `/webform_jsonschema/${webform_id}?_format=json`;

        this.setState({ loading: true });
    
        await axios.get(
            `${process.env.SOFT4NET_SOURCE_BASE_URL}/${this.intl.locale}${formSchemaEndpoint}`, {
                auth: {
                    username: process.env.BASIC_AUTH_USERNAME,
                    password: process.env.BASIC_AUTH_PASSWORD,
                }
                // headers: {
                //   'Access-Control-Allow-Origin': '*',
                // },
                // crossdomain: true
            })
            .then(response => {
                // console.log(response)
                // console.log(JSON.stringify(response, null, 4))
                // const { data: { data: { 0: { attributes } } } } = response; // === const attributes = response.data.data[0].attributes
                
                // console.log(response.data)

                this.setState({
                    loading: false,
                    formSchema: response.data,
                })
            })
            .catch(error => {
                //setState({ loading: false, error })
                console.log(error)
            })
            .finally(() => {
                // always executed
                // setState({ loading: false }) // do not modify state to offten because it re-renders and it takes time
                // this.formSchema = state.formSchema;
            });
    };

    getField = (fieldId) => {
        return this.state.formSchema && this.state.formSchema.schema && this.state.formSchema.schema.properties[fieldId] ? this.state.formSchema.schema.properties[fieldId] : null;
    };
    
    getFieldTitle = (fieldId) => {
        return this.getField(fieldId) ? this.getField(fieldId).title : ``;
    };

    getFieldItems = (fieldId) => {
        let items = [];
        const field = this.getField(fieldId);

        if (null === field) {
            return items;
        }

        // console.log(field);

        if (typeof field.items === `undefined`) {
            items = field.anyOf ? field.anyOf : items;
        } else {
            items = field.items.anyOf ? field.items.anyOf : items;
        }

        return items;
    };

    getFieldOptionEnum = (fieldId, index) => {
        return this.getField(fieldId) ? this.getField(fieldId).anyOf[index].enum[0] : ``;
    };

    getFieldOptionTitle = (fieldId, index) => {
        return this.getField(fieldId) ? this.getField(fieldId).anyOf[index].title : ``;
    };

    // For now this is just only for heuresis_outsourcing_diagnoza_fi.js!!!
    initialValuesPrepare = (initialValues) => {
        this.state.formSchema && this.state.formSchema.schema && Object.keys(this.state.formSchema.schema.properties).map(fieldId => {
          if (`radio` !== this.state.formSchema.ui[fieldId][`ui:widget`]) { // skip non option value
            return null;
          }
    
          // const field = state.formSchema.schema.properties[fieldId];
          initialValues[fieldId] = ``;
        })
    
        return initialValues;
    }
    
    // For now this is just only for heuresis_outsourcing_diagnoza_fi.js!!!
    validationSchemaPrepare = (Yup) => {
        let validationSchema = {};

        this.state.formSchema && this.state.formSchema.schema && Object.keys(this.state.formSchema.schema.properties).map(fieldId => {
            if (`e_mail` === fieldId) {
                validationSchema[fieldId] = Yup.string().email().required();
            }
            else if (`radio` === this.state.formSchema.ui[fieldId][`ui:widget`]) { // option value
                validationSchema[fieldId] = Yup.string().required();
            }
            else {
                validationSchema[fieldId] = Yup.string().required();
            }
        })

        return validationSchema;
    }

    // },
    onSubmit = async (values, actions) => {
        //setSubmitting(true);
        actions.setStatus(undefined);
    
        // @see: https://www.drupal.org/project/webform_rest
        // set "webform_id": "contact_s4n",
        // values.webform_id = webform_id;

        // URL from which form was sent START
        let windowLocationHref = ``;
        if (typeof window !== `undefined`) {
            windowLocationHref = window.location.href;
        }
        values.url = {
            title: `${windowLocationHref}`,
            url: `${windowLocationHref}`,
            target: "_blank",
        };
        // URL from which form was sent STOP

        
        axios.defaults.baseURL = process.env.SOFT4NET_SOURCE_BASE_URL;    
        
        const tokenResponce = await axios.get('/rest/session/token');
        const csrfToken = tokenResponce.data;
    
        const response = await axios.post('/webform_rest/submit',
            values, 
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken,
                },
                auth: {
                    username: process.env.BASIC_AUTH_USERNAME,
                    password: process.env.BASIC_AUTH_PASSWORD,
                }
            },
        );
    
        // console.log(response)
        // {
        //   "sid": "1"
        // }
    
        if (response.status === 200 && response.data.sid) {
            this.setFormStatus({
                success: true,
                class: 'success',
                // message: ReactDOMServer.renderToStaticMarkup(<FormattedMessage id="soft4net_form_status_success" />),
                message: this.t({ id: "soft4net_form_status_success" }),
            });
    
            // @todo: this is not working check why!?
            actions.setStatus({
                success: true
            });
          
            actions.resetForm();
        } else {
            let message = `${this.t({ id: "soft4net_form_status_danger" })}<br />\n`;
            if (response.data.error) {
                for (const field in response.data.error) {
                    // message += `${field}: ${response.data.error[field]}<br />\n`;
                    message += `- ${response.data.error[field]}<br />\n`;
                }
            }
    
            this.setFormStatus({
                success: true,
                class: 'danger',
                message: message
            });
        }
    
        actions.setSubmitting(false);
    }
}

export default FormHelper;